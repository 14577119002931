import WowsCommerce, { EVENTS, LOG_LEVELS, PLATFORMS } from '@wg/wows-commerce'
import { DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents'
import preloaded from '~/preloaded'

const wsmartStorefrontDebug = preloaded.settings.wsmartStorefrontDebug

const wowsCommerce = new WowsCommerce({
    productsValidationEnabled: false,
    backendUrl: preloaded.urls.wsmartUrl,
    logLevel: wsmartStorefrontDebug ? LOG_LEVELS.DEBUG : LOG_LEVELS.ERROR,
    language: preloaded.settings.languageCode,
    showDevPanel: wsmartStorefrontDebug,
    storefrontName: preloaded.settings.wsmartStorefront,
    userSupportUrl: preloaded.urls.supportUrl,
    fraudDetectionClosableByPlatform: true,
    fraudDetectionDialogEnabled: false,
    webPurchaseConfirmationIframeStyles: {
        'background-color': 'transparent',
    },
    accountCompletionPlatforms: [PLATFORMS.VK],
    analytics: {
        projectName: 'dockyard',
        sendInterval: 3,
        maxBatchSize: 5,
    },
    needToUpdateControlState: false,
    realm: preloaded.settings.realm,
})

if (window.dockyard.urls.wsmartUrl) {
    wowsCommerce.checkPurchaseRestrictionByCountryMismatch()

    wowsCommerce.subscribe(EVENTS.PRODUCTS_UPDATE, ({ storefrontName, products }) => {
        console.log('PRODUCTS_UPDATE', storefrontName, products)
    })

    wowsCommerce.subscribe(EVENTS.OVERLAY_VISIBILITY, ({ visible }) => {
        console.log('OVERLAY_VISIBILITY', visible)
    })

    wowsCommerce.analytics.addEvent(DWH_EVENTS.BIND_METHOD, { test: 1 })
}

export default wowsCommerce
